var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "div",
        { staticClass: "filter-bar p-2 mb-3" },
        [
          _c(
            "b-navbar",
            { attrs: { toggleable: "xl" } },
            [
              _c("b-navbar-toggle", { attrs: { target: "filter-collapse" } }),
              _c(
                "b-collapse",
                { attrs: { id: "filter-collapse", "is-nav": "" } },
                [
                  _c(
                    "button",
                    {
                      staticClass: "filter-btn reset",
                      on: {
                        click: function($event) {
                          return _vm.resetFilters()
                        }
                      }
                    },
                    [_vm._v("Reset Filters")]
                  ),
                  _c(
                    "div",
                    { staticClass: "d-inline-block ml-lg-4" },
                    [
                      _vm._v(" Search: "),
                      _c("b-form-input", {
                        staticClass: "select",
                        attrs: { debounce: "500" },
                        model: {
                          value: _vm.filters.text,
                          callback: function($$v) {
                            _vm.$set(_vm.filters, "text", $$v)
                          },
                          expression: "filters.text"
                        }
                      })
                    ],
                    1
                  ),
                  _c("b-form-select", {
                    staticClass: "select company",
                    attrs: {
                      options: _vm.bookkeepers,
                      "text-field": "fullname",
                      "value-field": "id",
                      plain: ""
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "first",
                        fn: function() {
                          return [
                            _c(
                              "b-form-select-option",
                              { attrs: { value: null } },
                              [_vm._v("Select Bookkeeper")]
                            )
                          ]
                        },
                        proxy: true
                      }
                    ]),
                    model: {
                      value: _vm.filters.bookkeeper,
                      callback: function($$v) {
                        _vm.$set(_vm.filters, "bookkeeper", $$v)
                      },
                      expression: "filters.bookkeeper"
                    }
                  }),
                  _c(
                    "button",
                    {
                      staticClass: "filter-btn reset button-right",
                      on: {
                        click: function($event) {
                          return _vm.exportData()
                        }
                      }
                    },
                    [
                      _vm._v("Export "),
                      _c("b-icon", { attrs: { icon: "download" } })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-overlay",
        { attrs: { show: _vm.loading } },
        [
          _c(
            "b-card",
            { staticClass: "card-border table-card text--black" },
            [
              _vm.totalItems > _vm.filters.max
                ? _c("b-pagination", {
                    staticClass: "float-right",
                    attrs: {
                      "total-rows": _vm.totalItems,
                      "per-page": _vm.filters.max,
                      "aria-controls": "documentsTable"
                    },
                    on: { change: _vm.changePage },
                    model: {
                      value: _vm.filters.page,
                      callback: function($$v) {
                        _vm.$set(_vm.filters, "page", $$v)
                      },
                      expression: "filters.page"
                    }
                  })
                : _vm._e(),
              _c("b-table", {
                attrs: {
                  items: _vm.companies,
                  "no-local-sorting": "",
                  fields: _vm.fields,
                  "sort-by": _vm.sortBy,
                  "sort-desc": _vm.desc,
                  striped: ""
                },
                on: {
                  "sort-changed": _vm.sortingChanged,
                  "update:sortBy": function($event) {
                    _vm.sortBy = $event
                  },
                  "update:sort-by": function($event) {
                    _vm.sortBy = $event
                  },
                  "update:sortDesc": function($event) {
                    _vm.desc = $event
                  },
                  "update:sort-desc": function($event) {
                    _vm.desc = $event
                  }
                },
                scopedSlots: _vm._u([
                  {
                    key: "cell(name)",
                    fn: function(data) {
                      return [
                        _c(
                          "router-link",
                          {
                            staticClass: "text--black u",
                            attrs: { to: "/admin/company/" + data.item.id }
                          },
                          [_vm._v(" " + _vm._s(data.item.name) + " ")]
                        )
                      ]
                    }
                  },
                  {
                    key: "cell(organization)",
                    fn: function(data) {
                      return [
                        _vm._v(" " + _vm._s(data.item.organization) + " ")
                      ]
                    }
                  }
                ])
              }),
              _vm.totalItems > _vm.filters.max
                ? _c("b-pagination", {
                    staticClass: "float-right",
                    attrs: {
                      "total-rows": _vm.totalItems,
                      "per-page": _vm.filters.max,
                      "aria-controls": "documentsTable"
                    },
                    on: { change: _vm.changePage },
                    model: {
                      value: _vm.filters.page,
                      callback: function($$v) {
                        _vm.$set(_vm.filters, "page", $$v)
                      },
                      expression: "filters.page"
                    }
                  })
                : _vm._e(),
              _c("div", { staticClass: "pl-1 text-left" }, [
                _vm._v(" Per page: "),
                _c(
                  "span",
                  {
                    staticClass: "ml-2 link",
                    class: _vm.filters.max == 10 ? "active" : "",
                    on: {
                      click: function($event) {
                        _vm.filters.max = 10
                      }
                    }
                  },
                  [_vm._v("10")]
                ),
                _c(
                  "span",
                  {
                    staticClass: "ml-2 link",
                    class: _vm.filters.max == 25 ? "active" : "",
                    attrs: { href: "javasript:void(0)" },
                    on: {
                      click: function($event) {
                        _vm.filters.max = 25
                      }
                    }
                  },
                  [_vm._v("25")]
                ),
                _c(
                  "span",
                  {
                    staticClass: "ml-2 link",
                    class: _vm.filters.max == 50 ? "active" : "",
                    attrs: { href: "javasript:void(0)" },
                    on: {
                      click: function($event) {
                        _vm.filters.max = 50
                      }
                    }
                  },
                  [_vm._v("50")]
                ),
                _c(
                  "span",
                  {
                    staticClass: "ml-2 link",
                    class: _vm.filters.max == 100 ? "active" : "",
                    attrs: { href: "javasript:void(0)" },
                    on: {
                      click: function($event) {
                        _vm.filters.max = 100
                      }
                    }
                  },
                  [_vm._v("100")]
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }