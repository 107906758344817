import { render, staticRenderFns } from "./Company.vue?vue&type=template&id=5396133a&scoped=true&"
import script from "./Company.vue?vue&type=script&lang=js&"
export * from "./Company.vue?vue&type=script&lang=js&"
import style0 from "./Company.vue?vue&type=style&index=0&id=5396133a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5396133a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/websites/streamlined-business-solutions/sbs-client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5396133a')) {
      api.createRecord('5396133a', component.options)
    } else {
      api.reload('5396133a', component.options)
    }
    module.hot.accept("./Company.vue?vue&type=template&id=5396133a&scoped=true&", function () {
      api.rerender('5396133a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/reports/Company.vue"
export default component.exports